<template>
  <!-- 老人签到 -->
  <div class="wrapper">
      <div class="header">
        <el-row :gutter="20">
          <el-col :span="3">
            <el-button type="primary" icon="el-icon-edit" size='medium' @click="startSwip">开始刷卡</el-button>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" icon="el-icon-edit" size='medium'  @click="endSwip">结束刷卡</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="main">
        <el-table
        stripe
       
        :data="tableData"
        >
      <el-table-column
        type="index"
        align="center"
        label="序号"
        >
      </el-table-column>
       <el-table-column
        prop="signDateFrom"
        label="签入日期"
        align="center"
        >
      </el-table-column>
      <el-table-column
        prop="signDateTo"
        label="签出日期"
        align="center"
        >
      </el-table-column>
      <el-table-column
        prop="oldPersonName"
        label="姓名"
        align="center"
        v-if="personSignType==1"
        >
      </el-table-column>
      <el-table-column
        prop="workPersonName"
        label="姓名"
        align="center"
        v-if="personSignType==2"
        >
      </el-table-column>
      <el-table-column
        prop="bodyTemperature"
        label="体温"
        align="center">
      </el-table-column>
      <el-table-column
        prop="dicDescriptions"
        label="服务项目"
        align="center"
        v-if="personSignType==1"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="dicDescriptions"
        label="职业"
        align="center"
        v-if="personSignType==2"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        label="服务起止时间"
        align="center"
        >
        <template slot-scope="scope">
          <p>{{ scope.row.signTimeFrom|timeFilter}}</p>
          <p style="color:red">{{ scope.row.signTimeTo|timeFilter}}</p>
        </template>

      </el-table-column>
      <el-table-column
        prop="signDurationValue"
        label="服务时长"
        align="center">
      </el-table-column>
       <el-table-column
      label="操作"
      align="center">
      <template slot-scope="scope">
        <el-button @click="handleClick(scope.row)" type="text" size="small" disabled>编辑</el-button>
        <el-button type="text" size="small" disabled>删除</el-button>
      </template>
    </el-table-column>
    </el-table>
    </div>
      <div style="text-align: right; margin-top: 10px">
        <el-pagination
          background
          @size-change="handlePersonSizeChange"
          @current-change="handlePersonCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="total,jumper,prev, pager, next,sizes"
          :total="totalResult"
        ></el-pagination>
      </div>
      <swipingCard v-if="isSwipingCard" ref="swipingCard" :signTitle='signTitle' :personSignType='personSignType'/>
  </div>
</template>

<script>
import swipingCard from './swipingCard.vue'
import {mapActions } from 'vuex'

export default {
  name:'personSign',
  filters:{
    timeFilter(row){
      let time = ''
      if(row!=null){
        time = row.substring(0,row.lastIndexOf(":"))
      return time
      }
      
    }
  },
  components:{
    swipingCard
  },

  created(){
    // console.log('personSign');
    this.getList()

  },
    props: {
      personSignType: {
        required: true,
        type: Number
      }
    },
  data(){
    return {
      tableData:[],
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        signTitle:'开始刷卡',
        isSwipingCard:false,
        // personSignType:1  //1老人签到 2从业人员签到
    }
  },
  methods:{
      ...mapActions([
      'getOldPersonSignList',
      'getWorkPersonSignList',
      

    ]),
    startSwip(){
      this.isSwipingCard = true
      this.signTitle='开始刷卡'
    },
    endSwip(){
      this.isSwipingCard = true
      this.signTitle='结束刷卡'
    },
    //老人打卡列表
    getList(){
      if(this.personSignType==1){
        let param = {
          'sceneId':this.$route.params.id,
          'pageNum':this.currentPage,
          'pageSize':this.pageSize,
          'scene':JSON.parse(this.$route.query.item).orgType
        }
        this.getOldPersonSignList(param).then((res) => {
          if (res.code == 200) {
            this.tableData = res.data.records
            this.totalResult = Number(res.data.total)
            // console.log(res,'res')
            
          }
        })
      }else if(this.personSignType==2){
         let param = {
          'sceneId':this.$route.params.id,
          'pageNum':this.currentPage,
          'pageSize':this.pageSize,
          'scene':JSON.parse(this.$route.query.item).orgType
        }
        this.getWorkPersonSignList(param).then((res) => {
          if (res.code == 200) {
            this.tableData = res.data.records
            this.totalResult = Number(res.data.total)
            // console.log(res,'res')
            
          }
        })
      }
      
    },
    handlePersonCurrentChange(val){
      this.currentPage = val
      this.getList()
    },
    handlePersonSizeChange(val){
      this.pageSize = val
      this.getList()
      
    }
  }
}
</script>

<style lang="scss" scoped>
.header{
  // height: 50%;
  // width: 100%;
  padding-bottom: 10px;
  border-bottom: solid 1px #e4e7ed;
  margin-bottom: 10px;
}
.el-table .cell, .el-table th div {
	padding-right: 0;
}
</style>>
